<template>
  <div class="Afiliates">
    <b-image :src="banner" />
    <div class="section search-section">
      <div class="columns is-multiline is-centered">
        <div class="column has-text-centered">
          <b-dropdown position="is-bottom-left">
            <template #trigger="{ active }">
              <b-button
                :icon-right="active ? 'chevron-up' : 'chevron-down'"
                class="is-azur is-light"
                size="is-medium"
                rounded
              >
                {{ selectedState.location }}
              </b-button>
            </template>
            <b-dropdown-item
              class="has-text-zarzamora"
              v-for="unselected in unselectedStates"
              :key="unselected.text"
              @click="changeSelect(unselected)"
            >
              {{ unselected.location }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-full">
          <p class="title is-2 has-text-centered has-text-zarzamora">
            {{ $t('affiliates.title') }}
          </p>
        </div>
        <div class="column is-three-fifths">
          <b-field>
            <b-input
              id="search"
              :placeholder="$t('affiliates.search-label')"
              size="is-medium"
              type="search"
              icon-right="magnify"
              v-model="query"
              icon-right-clickable
              @icon-right-click="fetchStores(query)"
            >
            </b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="section">
      <sn-stores :stores="stores" />
      <sn-load-more-button
        :text="$t('affiliates.load-more')"
        @button-click="fetchStores"
      />
    </div>
  </div>
</template>
<script>
import SnLoadMoreButton from '@/components/SnLoadMoreButton'
import SnStores from '@/components/SnStores'

export default {
  name: 'Afiliates',
  components: { SnStores, SnLoadMoreButton },
  data() {
    return {
      query: '',
      page: 1,
      has_more: true,
      stores: [],
      states: [
        {
          id: 0,
          selected: true,
          location: 'Nuevo León',
        },
        {
          id: 1,
          selected: false,
          location: 'CDMX',
        },
        {
          id: 2,
          selected: false,
          location: 'Jalisco',
        },
        {
          id: 3,
          selected: false,
          location: 'Querétaro',
        },
      ],
    }
  },
  computed: {
    unselectedStates() {
      return this.states.filter((state) => state.selected === false)
    },
    selectedState() {
      const filter = this.states.filter((state) => state.selected === true)
      return Object.assign({}, filter[0])
    },
    banner() {
      return this.$i18n.locale === 'es'
        ? '/static/update/Banner-Increíbles-ahorros.jpg'
        : '/static/update/Banner-Incredible-Savings.jpg'
    },
  },
  methods: {
    restoreValues() {
      this.stores = []
      this.page = 1
      this.has_more = true
    },
    changeSelect(newSelect) {
      this.states[this.selectedState.id].selected = false
      this.states[newSelect.id].selected = true
      this.restoreValues()
      this.fetchStores()
    },
    addStores(stores) {
      stores.forEach((store) => {
        if (!this.stores.includes(store.logo) && store.logo != null) {
          this.stores.push(store.logo)
        }
      })
    },
    fetchStores(query) {
      if (query) {
        this.restoreValues()
      }

      const baseUrl = `https://adminshopnet.com/api/v1/web_images/${this.page}`
      const location = `?location=${this.selectedState.location}`
      const params = query ? `&q=${query}` : ''

      //      console.log(baseUrl + location + params)

      if (this.has_more) {
        fetch(baseUrl + location + params)
          .then((res) => res.json())
          .then((data) => {
            this.addStores(data.logos)
            this.has_more = data.has_more
            this.page = data.next_page
          })
      }
    },
  },
  created() {
    this.fetchStores()
  },
}
</script>
<style>
.search-section {
  background: transparent linear-gradient(180deg, #c3d7ee 0%, #ddedff 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #a2b9d4;
  opacity: 1;
}
.search-section input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #a2b9d4;
  border-radius: 13px;
  opacity: 1;
}
.dropdown-menu {
  left: 0 !important;
}
a.dropdown-item {
  padding-right: 1.5rem !important;
}
</style>
