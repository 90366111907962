<template>
  <div class="Stores">
    <div class="section">
      <div class="columns is-multiline">
        <div
          v-for="store in stores"
          :key="store"
          class="column is-one-quarter is-flex is-justify-content-center"
        >
          <div class="card">
            <div class="card-image">
              <figure class="image is-200x200">
                <img class="img-border" :src="store" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SnStores',
  props: {
    stores: {
      type: Array,
      default: function () {
        return ['']
      },
    },
  },
}
</script>
<style>
.card {
  box-shadow: 0px 4px 8px #307fe240;
  border: 1px solid #307fe2;
  border-radius: 20px;
  opacity: 1;
}
.card img {
  border: 3px solid #ffffff;
  border-radius: 17px;
  opacity: 1;
}
</style>
<style scoped>
.image img {
  height: 100% !important;
}
</style>
