<template>
  <div class="loadmore">
    <div class="container is-flex is-justify-content-center">
      <b-button type="is-azur is-inverted" size="is-large" @click="buttonClick"
        >{{ text }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadMoreButton',
  methods: {
    buttonClick() {
      this.$emit('button-click')
    },
  },
  props: {
    text: {
      type: String,
      defautl: 'Default Button Text',
    },
  },
}
</script>
